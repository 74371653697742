import ReactDOM from 'react-dom'
import API from 'api/api';
import ICONS from 'assets/icons';
import Button from 'components/UI/Button/Button';
import { BUTTON_THEMES, SIZES } from 'config/constants';
import { useDidMount, useToggle } from 'hooks';
import { useEffect, useState } from 'react';
import Spinner from 'components/UI/Spinner/Spinner';
import ContactItemBlock from 'components/UI/ContactItemBlock/ContactItemBlock';
import { CONTACT_INFO_DATE_CONFIG } from 'config/dates-сonfig';
import Counter from 'components/UI/Counter/Counter';
import DateTime from 'components/DateTime';

const ShowExtendedBookingInfo = (props) => {
  const [isExtendedBookingInfoShowed, toggleExtendedBookingInfoShowed] = useState(false);
  const [bookingInfoLoading, toggleBookingInfoLoading] = useToggle(true);
  const [extendedBookingInfo, setExtendedBookingInfo] = useState([]);

  useEffect(() => {
    if (!isExtendedBookingInfoShowed) return;
    
    API.getExtendedBookingInfoByCallerId(props.activeRecipient.id)
      .then(({ data }) => {
        setExtendedBookingInfo(data);
      })
      .catch(console.error)
      .finally(() => toggleBookingInfoLoading(false));
  }, [isExtendedBookingInfoShowed]);

  return (
    <>
      <Button
        icon={ICONS.eyeNew}
        onMouseEnter={() => toggleExtendedBookingInfoShowed(true)}
        onMouseLeave={() => toggleExtendedBookingInfoShowed(false)}
        {...props}
      />

      {isExtendedBookingInfoShowed && ReactDOM.createPortal(
        <div
          className="chat-header__floating-container"
          onMouseEnter={() => toggleExtendedBookingInfoShowed(true)}
          onMouseLeave={() => toggleExtendedBookingInfoShowed(false)}
        >
          <div className='chat-header__floating-content'>
            {bookingInfoLoading
              ? <Spinner spinnerSize={30} />
              : extendedBookingInfo.map((girlInfo, index) => (
                <ContactItemBlock key={index}>
                  <ContactItemBlock.Avatar src={girlInfo.avatar} />
                  <ContactItemBlock.Body>
                    <ContactItemBlock.Body.Name>{girlInfo.girlName}</ContactItemBlock.Body.Name>

                    <ContactItemBlock.Body.ExtraContent />

                    <ContactItemBlock.Body.ExtraContent>
                      <span>Last Seen:</span> <DateTime date={girlInfo.lastBookingDate} config={CONTACT_INFO_DATE_CONFIG} />
                    </ContactItemBlock.Body.ExtraContent>

                    <ContactItemBlock.Body.ExtraContent>
                      <Counter
                        icon={ICONS.eyeNew}
                        count={girlInfo.bookingsCount}
                        axis="horizontal"
                      />
                    </ContactItemBlock.Body.ExtraContent>
                  </ContactItemBlock.Body>
                </ContactItemBlock>
              ))}
          </div>
        </div>,
        props.contentContainer.current
      )}
    </>
  )
}

export default ShowExtendedBookingInfo;
