import React  from 'react';

import { BUTTON_THEMES, SIZES } from 'config/constants';

import ICONS from 'assets/icons';
import Button from 'components/UI/Button/Button';


const CopyToClipboardButton = (props) => {
  return (
    <Button
      title={props.title ? props.title : "Copy"}
      icon={ICONS.copyTransparent}
      disabled={!props.value}
      onClick={() => window.navigator.clipboard.writeText(props.value)}
      showChecked
      {...props}
    />
  )
}

export default CopyToClipboardButton;
