import classNames from 'classnames';

import { BUTTON_THEMES, GROUPED_BUTTONS_THEMES, SIZES } from 'config/constants';

import './Header.scss';
import Navbar from './components/Navbar/Navbar';
import UserMenu from './components/UserMenu/UserMenu';
import ActiveCall from './components/ActiveCall/ActiveCall';
import ActiveConference from './components/ActiveConference/ActiveConference';
import BellButton from 'components/Buttons/BellButton';
import BookingButton from 'components/Buttons/BookingButton';
import BotButton from 'components/Buttons/BotButton';
import DialpadButton from 'components/Buttons/DialpadButton';
import HistoryButton from 'components/Buttons/HistoryButton';
import MapButton from 'components/Buttons/MapButton';
import MultiMessageButton from 'components/Buttons/MutliMessageButton';
import VoicemailButton from 'components/Buttons/VoicemailButton';
import TasksButton from 'components/Buttons/TasksButton';
import GroupedButtons from 'components/UI/GroupedButtons/GroupedButtons';

const Header = (props) => {
  const generalButtonProps = {
    theme: BUTTON_THEMES.NONE_DARK,
    iconSize: SIZES.S,
    isBroad: true,
  }

  return (
    <header className="header">
      <div className="container">
        <div className="header__item">
          <UserMenu
            className="header__user-menu"
            setPagePending={props.setPagePending}
          />

          <GroupedButtons
            className="header__button-container"
            theme={GROUPED_BUTTONS_THEMES.DIM_ANIMATED}
            generalButtonProps={generalButtonProps}
          >
            <BellButton />
            <HistoryButton />
            <VoicemailButton />
            <DialpadButton />
          </GroupedButtons>
        </div>

        <div className="header__item">
          <Navbar isButtonsDisabledWhenPageOpenned />
        </div>

        <div className="header__item">
          <GroupedButtons
            className="header__button-container"
            theme={GROUPED_BUTTONS_THEMES.DIM_ANIMATED}
            generalButtonProps={generalButtonProps}
          >
            <BookingButton />
            <MapButton />
            <TasksButton />
            <MultiMessageButton />
            <BotButton />
          </GroupedButtons>
        </div>

        {/* <div className="header__item header__item--active-call">
          <ActiveCall />
          <ActiveConference />
        </div> */}
        {/* [ ]: Don't know how to distribute this component */}
      </div>
    </header>
  )
}

export default Header;
