import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import API from 'api/api';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { addWidgetReminders } from 'redux/ducks/notifications';
import { selectAllTaskReminders } from 'components/FilteredBookings/selectors/selectors';
import { usePrevious } from 'hooks';

import './NotificationsButton.scss';
import NotificationsWidget from './NotificationsWidget/NotificationsWidget';
import Button from 'components/UI/Button/Button';
import { BUTTON_THEMES, SIZES } from 'config/constants';
import Counter from 'components/UI/Counter/Counter';


const NotificationsButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [callerIdsOfReminders, setCallerIdsOfReminders] = useState([]);

  const remindersFromState = useSelector(selectAllTaskReminders);
  const remindersCount = useSelector(state => state.notifications.remindersCount);
  const webmasterRemindersCount = useSelector(state => state.notifications.webmasterRemindersCount);

	const previousRemindersCount = usePrevious(remindersFromState.length);

  const dispatch = useDispatch();

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    visible: isVisible,
    placement: 'right',
    onVisibleChange: setIsVisible,
  });

  const handleClose = () => setIsVisible(false);

  const handleGetContactReminders = (offset = 0) => {
    API.getAllContactReminders(offset)
      .then(res => {
        // const upgradedData = res.data?.map(item => {
        //   return {
        //     ...item,
        //     body: item.interaction.message,
        //     data: {
        //       interaction: {
        //         ...item,
        //         caller: {
        //           id: item.caller_id,
        //           photo: item.caller_photo,
        //           type: item.caller_type,
        //         },
        //       },
        //       message_id: item.message_id,
        //     },
        //     reminder: { id: item.id }
        //   }
        // }) || [];

        const callerIds = res.data.data.map(item => item.caller_id);
        setCallerIdsOfReminders(callerIds);

        dispatch(addWidgetReminders(res.data));
      })
      .catch(console.error);
  }

  useEffect(() => {
    // logic to save reminders to state from response

    if (!remindersFromState?.length) {
      handleGetContactReminders();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('notificationButtonClick', handleClose);

    return () => {
      window.removeEventListener('notificationButtonClick', handleClose);
    }
  })

  useEffect(() => {
    if (remindersFromState.length === 10 && previousRemindersCount === 11) {
      handleGetContactReminders(10);
    }
  }, [remindersFromState]);

  if (!remindersCount && !webmasterRemindersCount) {
    return null;
  }

  return (
    <>
      <Button
        className="widget-btn"
        radius={SIZES.M}
        ref={setTriggerRef}
        squared
        icon={ICONS.bellNew}
        iconSize={SIZES.S}
      >
        <Counter
          className="widget-btn__top-counter"
          count={remindersCount}
          color="--red-status-color"
        />

        <Counter
          className="widget-btn__bottom-counter"
          count={webmasterRemindersCount}
          color="--orange-status-color"
        />
      </Button>

      {visible &&
        <div
          ref={setTooltipRef}
          {...getTooltipProps()}
          className="widget-btn__content-wrapper"
        >
          <NotificationsWidget
            list={remindersFromState}
            onClose={handleClose}
            handleGetContactReminders={handleGetContactReminders}
            callerIdsOfReminders={callerIdsOfReminders}
          />
        </div>
      }
    </>
  )
}

export default NotificationsButton;
