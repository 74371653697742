import ReactDOM from 'react-dom'
import ICONS from 'assets/icons'
import Button from 'components/UI/Button/Button'
import { BUTTON_THEMES, SIZES } from 'config/constants'
import { useToggle } from 'hooks'
import { useState } from 'react'
import CopyToClipboardButton from './CopyToClipboardButton'


const ShowContactNotesButton = (props) => {
  const [contactNotesShowed, toggleContactNotesShowed] = useState(false);

  const { note } = props.activeRecipient;

  return (
    <>
      <Button
        icon={ICONS.note}
        disabled={!props.activeRecipient.note}
        onMouseEnter={() => note && toggleContactNotesShowed(true)}
        onMouseLeave={() => note && toggleContactNotesShowed(false)}
        {...props}
      />

      {contactNotesShowed && ReactDOM.createPortal(
        <div
          className="chat-header__floating-container"
          onMouseEnter={() => {
            toggleContactNotesShowed(true)
          }}
          onMouseLeave={() => toggleContactNotesShowed(false)}
        >
          <div className="chat-header__floating-content">
            <div className="chat-header__floating-header">
              <p className="chat-header__floating-title">
                <ICONS.note /> <span>Notes</span>
              </p>

              <CopyToClipboardButton
                theme={BUTTON_THEMES.PRIMARY}
                iconSize={SIZES.M}
                value={note}
              />

            </div>

            {note}
          </div>
        </div>,
        props.contentContainer.current
      )}
    </>
  )
}

export default ShowContactNotesButton;
