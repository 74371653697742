import API from 'api/api';
import ICONS from 'assets/icons';
import Button from 'components/UI/Button/Button';
import { BUTTON_THEMES, SIZES } from 'config/constants';

const TakeChatButton = ({ userId, activeRecipient, ...restProps }) => {
  const isTaken = activeRecipient.relatedUserId && activeRecipient.relatedUserId === userId;

  const changeRelatedStatus = () => {
    const apiAction = !isTaken ? "lock" : "unlock";

    return API.lockContact(apiAction, activeRecipient.id);
  };

  return (
    <Button
      title={isTaken ? "Unlock" : "Lock"}
      icon={isTaken ? ICONS.handRock : ICONS.handPaper}
      disabled={!activeRecipient.id}
      onClick={changeRelatedStatus}
      active={isTaken}
      {...restProps}
    />
  )
}

export default TakeChatButton;
