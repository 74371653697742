import { useToggle } from 'hooks';
import { BUTTON_THEMES, SIZES } from 'config/constants';

import ICONS from 'assets/icons';
import ProfileRates from 'components/ProfileRates/ProfileRates';
import Button from 'components/UI/Button/Button';
import Portal from 'components/Portal';


const ShowGirlRatesButton = (props) => {
  const [isRatesOpen, toggleRatesOpen] = useToggle(false);

  return (
    <>
      <Button
        icon={ICONS.poundSign}
        onMouseEnter={() => toggleRatesOpen(true)}
        onMouseLeave={() => toggleRatesOpen(false)}
        disabled={!props.profile}
        {...props}
      />

      {isRatesOpen && (
        <Portal>
          <div className="chat-header__rates">
            <ProfileRates
              isEditor
              profileId={props.profile?.id}
              isMember={props.profile?.is_for_member || false}
              defaultPrices={props.profile?.prices || []}
              memberPrices={props.profile?.prices_member || []}
              secretPrices={props.profile?.prices_secret || []}
            />
          </div>
        </Portal>
      )}
    </>
  )
}

export default ShowGirlRatesButton;
