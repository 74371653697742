import React from 'react';
import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import { classModifier } from 'utils';

import "./GroupedButtons.scss";
import Button from '../Button/Button';
import ICONS from 'assets/icons';
import Overflow from 'rc-overflow';


const GroupedButtons = (props) => {
  const {
    className,
    children,
    theme,
    isDropdowned,
    buttonComponent,
    generateButtonProps,
    generalButtonProps,
    dropdownTheme,
    dropdownClassName,
    dropdownButtonProps,
  } = props;

  const {
    visible: isRevealed,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    trigger: 'click',
    mutationObserverOptions: null,
  });

  const getGroupedButtonsClasses = (theme) => classModifier('grouped-buttons', theme && 'theme-' + theme);

  const createButtons = () => {
    const passedProps = {
      className: 'grouped-buttons__button',
      radius: null,
      ...generalButtonProps,
    }

    const generateButtons = (buttonProps) => (
      buttonProps.map((props, index) => (
        <Button
          key={index}
          component={buttonComponent}
          {...props}
          {...passedProps}
        />
      ))
    )

    if (!isDropdowned) {
      if (children) {
        return React.Children.map(children, (child) => React.cloneElement(child, passedProps));
      }

      return generateButtons(generateButtonProps());
    }

    if (children) {
      return (
        <Overflow
          className={classNames(getGroupedButtonsClasses(theme))}
          data={children}
          renderItem={(item) => React.cloneElement(item, passedProps)}
          renderRest={(items) => (
            <Button
              className={classNames('grouped-buttons__dropdown-button', isRevealed && 'revealed')}
              ref={setTriggerRef}
              icon={ICONS.burgerMenu}
              radius={null}
              {...generalButtonProps}
              {...dropdownButtonProps}
            >
              <div
                className={classNames(dropdownClassName, getGroupedButtonsClasses(dropdownTheme || theme), [isRevealed && 'visible', 'grouped-menu'])}
                ref={setTooltipRef}
              >
                {/* <button className={`grouped-buttons__null-box`} /> */}
                {React.Children.map(items, (child) => React.cloneElement(child, passedProps))}
              </div>
            </Button>
          )}
          maxCount='responsive'
        />
      )
    }

    const buttonProps = generateButtonProps();

    const activeIndexIcon = buttonProps.findIndex((button) => button.active);
    const [activeProps] = activeIndexIcon !== -1
      ? buttonProps.splice(activeIndexIcon, 1)
      : [{ icon: ICONS.burgerMenu }];

    return ( // [ ]: implement overflowing buttons with Overflow component with array of dom elements
      <>
        <div className={classNames(getGroupedButtonsClasses(theme))}>
          <Button
            className={classNames(`grouped-buttons__burger-button`, 'dropdown-button', isRevealed && 'revealed')}
            ref={setTriggerRef}
            {...activeProps}
            {...generalButtonProps}
            {...dropdownButtonProps}
          >
            <div
              className={classNames(dropdownClassName, getGroupedButtonsClasses(dropdownTheme || theme), [isRevealed && 'visible', 'grouped-menu'])}
              ref={setTooltipRef}
            >
              {generateButtons(buttonProps)}
            </div>
          </Button>
        </div>

      </>
    )
  }

  return (
    <div className={classNames(className, getGroupedButtonsClasses(theme))}>
      {createButtons()}
    </div>
  )
}

export default GroupedButtons;
